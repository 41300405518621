import React, { useState } from "react"
import HomePageLayout from "../../components/layouts/HomePageLayout"
import SEO from "../../components/seo"
import generalClasses from "./general.module.css"

import circleIcon1 from "../../images/case-studies/1-plaud.png"
import circleIcon2 from "../../images/case-studies/2-plaud.png"
import circleIcon3 from "../../images/case-studies/3-plaud.png"

import plaudOverview from "../../images/case-studies/plaud-overview.jpg"
import plaudSolution from "../../images/case-studies/plaud-solution.jpg"
import plaudWebsite from "../../images/case-studies/plaud-website.jpg"
import Footer from "../../components/footer"
import RequestModal from "../../components/requestModal"

import { Link } from "gatsby"
import arrowLeft from "../../images/arrow-left.svg"
import LeftIcon from "../../images/arrow-left.inline.svg"
import RightIcon from "../../images/arrow-right.inline.svg"

const Plaud = props => {
  const [modalOpen, toggleModalOpen] = useState(false)

  return (
    <HomePageLayout pageTitle="plaud" background>
      <SEO title="Plaud Case Study" />
      <main className={generalClasses.main}>
        <Link to={"/solutions/products"} className={generalClasses.backLink}>
          <img
            src={arrowLeft}
            alt=""
            className={generalClasses.backLinkImage}
          />
          Products
        </Link>
        <section
          className={[
            generalClasses.caseStudyIntro,
            generalClasses.section,
          ].join(" ")}
        >
          <div>
            <h1 className={generalClasses.caseStudyHeader}>Plaud</h1>
            <p className={generalClasses.caseStudyDescription}>
              Plaud is a software for forecasting and managing data for
              electoral process or specific candidacy.
            </p>
            <p className={generalClasses.caseStudyCategory}>
              UI / UX | WEB DEVELOPMENT
            </p>
          </div>
          <ul className={generalClasses.caseStudyDetails}>
            <li className={generalClasses.caseStudyDetail}>
              <h4 className={generalClasses.caseStudyDetailHeader}>USERS</h4>
              <p className={generalClasses.caseStudyDetailContent}>
                1,000 - 1,000,000 Users
              </p>
            </li>
            <li className={generalClasses.caseStudyDetail}>
              <h4 className={generalClasses.caseStudyDetailHeader}>INDUSTRY</h4>
              <p className={generalClasses.caseStudyDetailContent}>
                Technology
              </p>
            </li>
          </ul>
        </section>
        <section
          className={[
            generalClasses.contentMargin,
            generalClasses.section,
          ].join(" ")}
        >
          <h1
            className={generalClasses.sectionHeader}
            style={{
              marginBottom: "40px",
            }}
          >
            MEASURABLE IMPACT
          </h1>
          <div className={generalClasses.grid3}>
            <div>
              <img
                src={circleIcon1}
                className={generalClasses.measurableImpactIcon}
                alt=""
              />
              <p className={generalClasses.sectionText}>
                Plaud has led to over 90% reduction in repetitive task (like
                requesting for customers measure details)
              </p>
            </div>
            <div>
              <img
                src={circleIcon2}
                className={generalClasses.measurableImpactIcon}
                alt=""
              />
              <p className={generalClasses.sectionText}>
                98% reduction in time taken to store and retrieve customers(such
                as measure and biodata)
              </p>
            </div>
            <div>
              <img
                src={circleIcon3}
                className={generalClasses.measurableImpactIcon}
                alt=""
              />
              <p className={generalClasses.sectionText}>
                Over 90% reduction in time taken to carryout administrative task
                such as taking inventory, invoicing and billing
              </p>
            </div>
          </div>
        </section>
        <section
          className={[
            generalClasses.imageGrid,
            generalClasses.section,
            generalClasses.imageGridLeft,
          ].join(" ")}
        >
          <img
            src={plaudOverview}
            className={generalClasses.displayImageFull}
            alt=""
          />
          <div>
            <h1 className={generalClasses.sectionHeader}>
              overview & challenges
            </h1>
            <p className={generalClasses.sectionText}>
              The fashion industry in Nigeria is very diverse with different
              cultural undertone and it has been in existence for a long time.
              The absence of a custom-made solution in the fashion industry for
              process automation poses a huge challenge. The series of
              challenges encountered makes business optimization in the industry
              very difficult, in terms of record-keeping (client measurement),
              communications within team members, recording(finance) and
              tracking inventories(delivering), storing clients’ data to avoid
              the repetitive process. These made it difficult for business
              owners in the fashion industry to manage their services well,
              keeping up with customers records and introduction of new
              customers through an analog mean consumes time and requires
              enormous financial and human capital.
            </p>
          </div>
        </section>
        <section
          className={[
            generalClasses.imageGrid,
            generalClasses.section,
            generalClasses.imageGridRight,
          ].join(" ")}
        >
          <div>
            <h1 className={generalClasses.sectionHeader}>solution</h1>
            <p className={generalClasses.sectionText}>
              Plaud is a retail management solution custom-made for fashion
              designers and tailors. The solution is an automated data-driven
              platform that manages Customer Relationship, Sales Order, Payment,
              Receipt, Inventory, data collection system, Data analysis systems,
              intra-communication system, and Embedded SMS (for external
              communications) amongst others. Plaud has helped our clients 1172
              to better monitor their transaction and manage their processes
              ranging from cloud storage of customer details(measurements) to
              taking inventory, managing payment and invoicing, coupled with a
              graphical presentation of the business analysis.
            </p>
          </div>
          <img
            src={plaudSolution}
            className={generalClasses.displayImageFull}
            alt=""
          />
        </section>
        <img
          src={plaudWebsite}
          alt=""
          className={[
            generalClasses.displayImageFull,
            generalClasses.contentMargin,
          ].join(" ")}
        />
        <section
          className={[
            generalClasses.contentMargin,
            generalClasses.section,
          ].join(" ")}
        >
          <h1 className={generalClasses.sectionHeader}>Result</h1>
          <p className={generalClasses.sectionText}>
            Plaud allows companies to link their internal and external processes
            more efficiently. Plaud has led to over 90% reduction in repetitive
            task (like requesting for customers measure details), 98% reduction
            in time taken to store and retrieve customers(such as measure and
            biodata), 90% reduction in time taken analyze company’s account,
            over 90% reduction in time taken to carryout administrative task
            such as taking inventory, invoicing and billing. Plaud ensures that
            business owners in the fashion industry can optimize their processes
            to achieve the best result.
          </p>
        </section>
        <div
          className={generalClasses.caseStudyPageLinks}
          style={{
            paddingBottom: "85px",
          }}
        >
          <Link
            to="/case-studies/totalmetrics"
            className={generalClasses.caseStudyPageLink}
          >
            <span className={generalClasses.caseStudyPageLinkIcon}>
              <LeftIcon
                className={generalClasses.caseStudyPageLinkIconImage}
                width="11px"
                height="18px"
              />
            </span>
            <p className={generalClasses.caseStudyPageLinkText}>
              <span className={generalClasses.caseStudyPageLinkTag}>
                PREVIOUS
              </span>
              <span className={generalClasses.caseStudyPageLinkTitle}>
                TotalMetrics
              </span>
            </p>
          </Link>
          <Link
            to="/case-studies/polleasy"
            className={[
              generalClasses.caseStudyPageLink,
              generalClasses.caseStudyPageLinkRight,
            ].join(" ")}
          >
            <p className={generalClasses.caseStudyPageLinkText}>
              <span className={generalClasses.caseStudyPageLinkTag}>NEXT</span>
              <span className={generalClasses.caseStudyPageLinkTitle}>
                Polleasy
              </span>
            </p>
            <span className={generalClasses.caseStudyPageLinkIcon}>
              <RightIcon
                className={generalClasses.caseStudyPageLinkIconImage}
                width="11px"
                height="18px"
              />
            </span>
          </Link>
        </div>
        <section
          className={[generalClasses.requestQuote, generalClasses.section].join(
            " "
          )}
        >
          <h1
            className={[
              generalClasses.sectionHeader,
              generalClasses.requestQuoteHeader,
            ].join(" ")}
          >
            learn more about plaud
          </h1>
          <button
            className={generalClasses.requestQuoteButton}
            onClick={() => toggleModalOpen(true)}
          >
            REQUEST A QUOTE
          </button>
        </section>
        <RequestModal open={modalOpen} toggleOpen={toggleModalOpen} />{" "}
      </main>
      <Footer />
    </HomePageLayout>
  )
}

export default Plaud
